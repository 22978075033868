module.exports = `
<div class="document-previewer-wrapper-a717db">
    <style>
        [data-custom-class='body'],
        [data-custom-class='body'] * {
            background: transparent !important;
        }

        [data-custom-class='title'],
        [data-custom-class='title'] * {
            font-family: Arial !important;
            font-size: 26px !important;
            color: #000000 !important;
        }

        [data-custom-class='subtitle'],
        [data-custom-class='subtitle'] * {
            font-family: Arial !important;
            color: #595959 !important;
            font-size: 14px !important;
        }

        [data-custom-class='heading_1'],
        [data-custom-class='heading_1'] * {
            font-family: Arial !important;
            font-size: 19px !important;
            color: #000000 !important;
        }

        [data-custom-class='heading_2'],
        [data-custom-class='heading_2'] * {
            font-family: Arial !important;
            font-size: 17px !important;
            color: #000000 !important;
        }

        [data-custom-class='body_text'],
        [data-custom-class='body_text'] * {
            color: #595959 !important;
            font-size: 14px !important;
            font-family: Arial !important;
        }

        [data-custom-class='link'],
        [data-custom-class='link'] * {
            color: #3030F1 !important;
            font-size: 14px !important;
            font-family: Arial !important;
            word-break: break-word !important;
        }
    </style>
    <div data-custom-class="body">
        <div align="center" style="text-align: left;">
            <div align="center" class="MsoNormal" style="text-align: center; line-height: 22.5px;"><a
                    name="_7m5b3xg56u7y"></a></div>
            <div align="center" class="MsoNormal" data-custom-class="subtitle"
                style="text-align: left; line-height: 150%;"><br></div>
            <div align="center" class="MsoNormal" data-custom-class="subtitle"
                style="text-align: left; line-height: 1.5;"><span
                    style="color: rgb(89, 89, 89); font-size: 14.6667px; text-align: justify;"><strong>Last updated <bdt
                            class="block-container question question-in-editor"
                            data-id="6d5ec16f-716c-32d6-1aa6-f4c1bd8cce1f" data-type="question">January 01, 2021</bdt>
                        </strong></span><br><a name="_gm5sejt4p02f"></a></div>
        </div>
        <div align="center" style="text-align: left;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" style="text-align:justify;line-height:115%;"><br></div>
            <div class="MsoNormal" style="text-align:justify;line-height:115%;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a
                    name="_6aa3gkhykvst"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">AGREEMENT TO
                        TERMS</span></strong></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">These Terms of Use constitute a legally binding
                    agreement made between you, whether personally or on behalf of an entity (“you”) and <bdt
                        class="block-container question question-in-editor"
                        data-id="9d459c4e-c548-e5cb-7729-a118548965d2" data-type="question">WIZBORD Inc.</bdt>
                    <bdt class="block-component"></bdt>, doing business as <bdt class="question">WIZDOUGH</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt> ("<bdt class="block-component"></bdt><strong>
                        <bdt class="question">WIZDOUGH</bdt>
                    </strong>
                    <bdt class="else-block"></bdt>", “<strong>we</strong>”, “<strong>us</strong>”, or
                    “<strong>our</strong>”), concerning your access to and use of the <bdt
                        class="block-container question question-in-editor"
                        data-id="fdf30452-99b8-c68b-5cdf-34af764cd1fd" data-type="question"><a
                            href="https://www.wizbord.com" target="_blank" data-custom-class="link">https://www.wizbord.com</a>
                    </bdt> website as well as any other media form, media channel, mobile website or mobile application
                    related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by
                    accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use.
                    IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
                    SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                </span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Supplemental terms and conditions or documents that may
                    be posted on the Site from time to time are hereby expressly incorporated herein by reference. We
                    reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at
                    any time and for any reason. We will alert you about any changes by updating the “Last updated” date
                    of these Terms of Use, and you waive any right to receive specific notice of each such change. It is
                    your responsibility to periodically review these Terms of Use to stay informed of updates. You will
                    be subject to, and will be deemed to have been made aware of and to have accepted, the changes in
                    any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use
                    are posted.</span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">The information provided on the Site is not intended for
                    distribution to or use by any person or entity in any jurisdiction or country where such
                    distribution or use would be contrary to law or regulation or which would subject us to any
                    registration requirement within such jurisdiction or country. Accordingly, those persons who choose
                    to access the Site from other locations do so on their own initiative and are solely responsible for
                    compliance with local laws, if and to the extent local laws are applicable.</span></div>
            <div class="MsoNormal" style="line-height: 115%;">
                <bdt class="block-component"></bdt>
                <bdt class="block-component">
                    <bdt class="block-component"></bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1;"><span style="font-size: 15px;"><br></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);">The <span
                        style="font-size: 15px; color: rgb(89, 89, 89);">Site is not tailored to comply with
                        industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA),
                        Federal Information Security Management Act (FISMA), etc.), so if your interactions would be
                        subjected to such laws, you may not use this Site. You may not use the Site in a way that would
                        violate the Gramm-Leach-Bliley Act (GLBA).<bdt class="block-component"></bdt>
                        <bdt class="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
                            <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                        </bdt>
                    </span></span></div>
        </div>
        <div align="center" data-custom-class="body_text" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                                <bdt class="block-component"></bdt>The
                                Site is intended for users who are at least 18 years old. Persons under the age
                                of 18 are not permitted to use or register for the Site.
                            </span></bdt>
                    </bdt>
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_o18_option" data-type="statement">
                        </bdt>
                    </bdt>
                </bdt>
            </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a
                    name="_b6y29mp52qvx"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">INTELLECTUAL PROPERTY
                        RIGHTS</span></strong></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Unless otherwise
                    indicated, the Site is our proprietary property and all source code, databases,
                    functionality, software, website designs, audio, video, text, photographs, and
                    graphics on the Site (collectively, the “Content”) and the trademarks, service
                    marks, and logos contained therein (the “Marks”) are owned or controlled by us or
                    licensed to us, and are protected by copyright and trademark laws and various
                    other intellectual property rights and unfair competition laws of the United
                    States, international copyright laws, and international conventions. The Content and the Marks are
                    provided on the
                    Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms
                    of Use, no part of the Site and no Content or Marks may be copied, reproduced,
                    aggregated, republished, uploaded, posted, publicly displayed, encoded,
                    translated, transmitted, distributed, sold, licensed, or otherwise exploited
                    for any commercial purpose whatsoever, without our express prior written
                    permission.</span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Provided that you
                    are eligible to use the Site, you are granted a limited license to access and
                    use the Site and to download or print a copy of any portion of the Content to
                    which you have properly gained access solely for your personal, non-commercial
                    use. We reserve all rights not expressly granted to you in and to the Site, the Content and the
                    Marks.</span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a
                    name="_5hg7kgyv9l8z"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">USER
                        REPRESENTATIONS</span></strong></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">By using the Site, you represent and warrant
                    that:</span>
                <bdt class="block-container if" data-type="if" id="d2d82ca8-275f-3f86-8149-8a5ef8054af6">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_account_option"
                            data-type="statement"></bdt>
                        <bdt data-type="body"><span style="color: rgb(89, 89, 89); font-size: 11pt;">(</span><span
                                style="color: rgb(89, 89, 89); font-size: 14.6667px;">1</span><span
                                style="color: rgb(89, 89, 89); font-size: 11pt;">) all registration information you
                                submit
                                will be true, accurate, current, and complete; (</span><span
                                style="color: rgb(89, 89, 89); font-size: 14.6667px;">2</span><span
                                style="color: rgb(89, 89, 89); font-size: 11pt;">) you will maintain the accuracy of
                                such information and promptly update such registration information as necessary;</span>
                        </bdt>
                    </bdt>
                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>&nbsp;
                </bdt><span style="color: rgb(89, 89, 89); font-size: 11pt;">(</span><span
                    style="color: rgb(89, 89, 89); font-size: 14.6667px;">3</span><span
                    style="color: rgb(89, 89, 89); font-size: 11pt;">) you have the legal capacity and you agree to
                    comply with these Terms of Use;</span>
                <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_u13_option" data-type="statement">
                        </bdt>&nbsp;
                    </bdt><span style="color: rgb(89, 89, 89); font-size: 11pt;">(</span><span
                        style="color: rgb(89, 89, 89); font-size: 14.6667px;">4</span><span
                        style="color: rgb(89, 89, 89); font-size: 11pt;">) you are not a
                        minor in the jurisdiction in which you reside<bdt class="block-container if" data-type="if"
                            id="76948fab-ec9e-266a-bb91-948929c050c9">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="user_o18_option"
                                    data-type="statement"></bdt>
                            </bdt>; (
                        </bdt></span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">5</span><span
                        style="color: rgb(89, 89, 89); font-size: 11pt;">) you will not access the Site
                        through automated or non-human means, whether through a bot, script or
                        otherwise; (</span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">6</span><span
                        style="color: rgb(89, 89, 89); font-size: 11pt;">) you will not use the Site for any illegal or
                        unauthorized
                        purpose; and (</span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">7</span><span
                        style="color: rgb(89, 89, 89); font-size: 11pt;">) your use of the Site
                        will not violate any applicable law or regulation.</span><span
                        style="color: rgb(89, 89, 89); font-size: 14.6667px;"></span>
                </bdt>
            </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" style="text-align: justify; line-height: 115%;">
                <div class="MsoNormal" style="line-height: 17.25px;">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                        <span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">If you provide
                            any information that is untrue, inaccurate, not current, or incomplete, we have the right to
                            suspend or terminate your account and refuse any and all current or future use of the Site
                            (or any portion thereof).</span></div>
                    <div class="MsoNormal" style="line-height: 1.1; text-align: left;">
                        <bdt class="block-component"></bdt>
                    </div>
                    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
                    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
                </div>
                <div class="MsoNormal" style="line-height: 1;">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="heading_1"
                                style="line-height: 1.5; text-align: left;"><strong><span
                                        style="line-height: 24.5333px; font-size: 19px;">USER
                                        REGISTRATION</span></strong></div>
                        </bdt>
                    </bdt>
                </div>
                <div class="MsoNormal" style="line-height: 1;"><br></div>
                <div class="MsoNormal" style="line-height: 1;">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="body_text"
                                style="text-align: left; line-height: 1.5;"><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">You may be
                                    required to register with the Site. You agree to keep your password confidential and
                                    will be responsible for all use of your account and password. We reserve the right
                                    to remove, reclaim, or change a username you select if we determine, in our sole
                                    discretion, that such username is inappropriate, obscene, or otherwise
                                    objectionable.<bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                                    </span></div>
                        </bdt>
                    </bdt>
                </div>
                <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a
                    name="_nds4qylockxx"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">PRODUCTS</span></strong></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="block-container if" data-type="if" id="b9812c02-490b-3a1e-9b3a-9a03c73ee63c">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="product_option"
                                data-type="statement"></bdt>
                        </bdt>All products are subject to availability<bdt class="block-container if" data-type="if"
                            id="35c07bc8-4217-594b-b7e7-1b4f0e8c56e4">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="product_option"
                                    data-type="statement"></bdt>
                            </bdt>. We reserve the right to
                            discontinue any products at any time for any reason. Prices for all products
                            are subject to change.
                        </bdt>
                    </bdt>
                </span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a
                    name="_ynub0jdx8pob"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">PURCHASES AND
                        PAYMENT</span></strong></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We accept the following forms of payment:</span></div>
            <div class="MsoNormal" style="text-align:justify;line-height:115%;">
                <div class="MsoNormal" style="text-align: left; line-height: 1;"><br></div>
            </div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="forloop-component"></bdt>- &nbsp;<bdt class="question">Visa</bdt>
                </span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="forloop-component"></bdt>- &nbsp;<bdt class="question">Mastercard</bdt>
                </span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="forloop-component"></bdt>- &nbsp;<bdt class="question">American Express</bdt>
                </span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="forloop-component"></bdt>- &nbsp;<bdt class="question">Discover</bdt>
                </span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="forloop-component"></bdt>- &nbsp;<bdt class="question">PayPal</bdt>
                </span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="forloop-component"></bdt>
                </span></div>
            <div class="MsoNormal" style="line-height: 1;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><br></span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You agree to provide current, complete, and accurate
                    purchase and account information for all purchases made via the Site. You further agree to promptly
                    update account and payment information, including email address, payment method, and payment card
                    expiration date, so that we can complete your transactions and contact you as needed. Sales tax will
                    be added to the price of purchases as deemed required by us. We may change prices at any time. All
                    payments shall be&nbsp;</span><span
                    style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">in <bdt
                        class="question">U.S. dollars</bdt>.</span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You agree to pay all charges at the prices then in
                    effect for your purchases and any applicable shipping fees, and you authorize us to charge your
                    chosen payment provider for any such amounts upon placing your order. <bdt
                        class="block-container if" data-type="if" id="9c0216a1-d094-fd73-a062-9615dc795ffc">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="recurring_charge_option"
                                data-type="statement"></bdt>
                            <bdt data-type="body">If your order is subject to recurring charges, then you consent to our
                                charging your payment method on a recurring basis without requiring your prior approval
                                for each recurring charge, until such time as you cancel the applicable order.&nbsp;
                            </bdt>
                        </bdt>
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>We reserve the right to correct
                    any errors or mistakes in pricing, even if we have already requested or
                    received payment.</span></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1;"><br></div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We reserve the
                    right to refuse any order placed through the Site. We may, in our sole discretion, limit or
                    cancel quantities purchased per person, per household, or per order. These restrictions may include
                    orders placed
                    by or under the same customer account, the same payment method, and/or orders
                    that use the same billing or shipping address. We reserve the right to limit or prohibit orders
                    that, in our sole
                    judgment, appear to be placed by dealers, resellers, or distributors.</span></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 115%;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt data-type="conditional-block" style="color: rgb(10, 54, 90); text-align: left;">
                        <bdt class="block-component" data-record-question-key="return_option" data-type="statement"
                            style="font-size: 15px;"></bdt>
                    </bdt>
                </span></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt data-type="conditional-block" style="color: rgb(10, 54, 90); text-align: left;">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong><span
                                        style="line-height: 24.5333px;">
                                        <bdt class="block-container if" data-type="if"
                                            id="09a19ea5-53d7-8b08-be6e-279bf01450e1">
                                            <bdt data-type="conditional-block"><span style="font-size: 19px;">
                                                    <bdt data-type="body">
                                                        <bdt class="block-component"></bdt>
                                                        <bdt data-type="body">REFUNDS</bdt>
                                                    </bdt>
                                                    <bdt data-type="conditional-block">
                                                        <bdt class="block-component" data-record-question-key="null"
                                                            data-type="statement"></bdt>
                                                    </bdt>
                                                </span></bdt><span style="font-size: 19px;">&nbsp;POLICY</span>
                                        </bdt>
                                    </span></strong></div>
                        </bdt>
                    </bdt>
                </span></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt data-type="conditional-block" style="color: rgb(10, 54, 90); text-align: left;">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="body_text"
                                style="font-size: 15px; line-height: 1.5;"><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">All sales
                                    are final and no refund will be issued.<span
                                        style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                        <bdt class="statement-end-if-in-editor" data-type="close"
                                            style="font-size: 15px; text-align: left;"></bdt>
                                    </span></span></div>
                        </bdt>
                    </bdt>
                </span></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt data-type="conditional-block" style="color: rgb(10, 54, 90); text-align: left;">
                        <bdt data-type="body">
                            <div class="MsoNormal" style="font-size: 15px; line-height: 1.5;"><br></div>
                        </bdt>
                    </bdt><br>
                </span> <a name="_h284p8mrs3r7"></a>
                <div class="MsoNormal" data-custom-class="heading_1" style="text-align: left; line-height: 1.5;">
                    <strong><span style="line-height: 24.5333px; font-size: 19px;">PROHIBITED ACTIVITIES</span></strong>
                </div>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">You may not access or
                        use the Site for any purpose other than that for which we make the Site available. The Site may
                        not be used in connection with any commercial endeavors except those that are specifically
                        endorsed or approved by us.</span></div>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
                <div class="MsoNormal" style="line-height: 17.25px;">
                    <div class="MsoNormal" style="line-height: 1.1;">
                        <div class="MsoNormal" style="line-height: 17.25px;">
                            <div class="MsoNormal" data-custom-class="body_text"
                                style="line-height: 1.5; text-align: left;"><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">As a user
                                    of the Site, you agree not to:</span></div>
                            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
                        </div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">1</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Systematically retrieve data or other content from the Site to
                                    create or compile, directly or indirectly, a collection, compilation, database, or
                                    directory without written permission from us.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">2</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Make any unauthorized use of the Site, including collecting
                                    usernames and/or email addresses of users by electronic or other means for the
                                    purpose of sending unsolicited email, or creating user accounts by automated means
                                    or under false pretenses.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">3</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Use a buying agent or purchasing agent to make purchases on the
                                    Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">4</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Use the Site to advertise or offer to sell goods and services.
                                </bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">5</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Circumvent, disable, or otherwise interfere with security-related
                                    features of the Site, including features that prevent or restrict the use or copying
                                    of any Content or enforce limitations on the use of the Site and/or the Content
                                    contained therein.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">6</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Engage in unauthorized framing of or linking to the Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">7</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Trick, defraud, or mislead us and other users, especially in any
                                    attempt to learn sensitive account information such as user passwords.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">8</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Make improper use of our support services or submit false reports
                                    of abuse or misconduct.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">9</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Engage in any automated use of the system, such as using scripts
                                    to send comments or messages, or using any data mining, robots, or similar data
                                    gathering and extraction tools.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">10</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Interfere with, disrupt, or create an undue burden on the Site or
                                    the networks or services connected to the Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">11</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Attempt to impersonate another user or person or use the username
                                    of another user.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">12</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Sell or otherwise transfer your profile.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">13</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Use any information obtained from the Site in order to harass,
                                    abuse, or harm another person.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">14</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Use the Site as part of any effort to compete with us or otherwise
                                    use the Site and/or the Content for any revenue-generating endeavor or commercial
                                    enterprise.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">15</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Decipher, decompile, disassemble, or reverse engineer any of the
                                    software comprising or in any way making up a part of the Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">16</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Attempt to bypass any measures of the Site designed to prevent or
                                    restrict access to the Site, or any portion of the Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">17</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Harass, annoy, intimidate, or threaten any of our employees or
                                    agents engaged in providing any portion of the Site to you.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">18</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Delete the copyright or other proprietary rights notice from any
                                    Content.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">19</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Copy or adapt the Site’s software, including but not limited to
                                    Flash, PHP, HTML, JavaScript, or other code.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">20</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Upload or transmit (or attempt to upload or to transmit) viruses,
                                    Trojan horses, or other material, including excessive use of capital letters and
                                    spamming (continuous posting of repetitive text), that interferes with any party’s
                                    uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters,
                                    or interferes with the use, features, functions, operation, or maintenance of the
                                    Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">21</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Upload or transmit (or attempt to upload or to transmit) any
                                    material that acts as a passive or active information collection or transmission
                                    mechanism, including without limitation, clear graphics interchange formats
                                    (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes
                                    referred to as “spyware” or “passive collection mechanisms” or “pcms”).</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">22</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Except as may be the result of standard search engine or Internet
                                    browser usage, use, launch, develop, or distribute any automated system, including
                                    without limitation, any spider, robot, cheat utility, scraper, or offline reader
                                    that accesses the Site, or using or launching any unauthorized script or other
                                    software.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">23</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                                    the Site.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                                <bdt class="block-container forloop" data-type="forloop"
                                    id="19beb913-5a5e-2b51-51f9-8600a8eb26c3" style="display: inline;">
                                    <bdt data-type="conditional-block" style="display: inline;">
                                        <bdt data-type="body" style="display: inline;"><span
                                                style="font-size: 14.6667px;font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -22.05pt; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">24</span>
                                        </bdt>
                                    </bdt>
                                </bdt><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89); font-size: 14.6667px;">.&nbsp;</span>
                                <bdt class="question">Use the Site in a manner inconsistent with any applicable laws or
                                    regulations.</bdt>
                            </span></div>
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; margin-left: 20px; text-align: left;"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="forloop-component"></bdt>
                            </span></div>
                    </div>
                    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                </div>
                <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                <div class="MsoNormal" style="line-height: 17.25px;">
                    <div class="MsoNormal" style="line-height: 1;">
                        <bdt class="block-container if" data-type="if" style="text-align: left;">
                            <bdt data-type="conditional-block">
                                <bdt data-type="body">
                                    <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;">
                                        <strong><span style="line-height: 24.5333px; font-size: 19px;">USER GENERATED
                                                CONTRIBUTIONS</span></strong></div>
                                </bdt>
                            </bdt>
                        </bdt>
                    </div>
                    <div class="MsoNormal" style="line-height: 1;"><br></div>
                    <div class="MsoNormal" style="line-height: 1;">
                        <bdt class="block-container if" data-type="if" style="text-align: left;">
                            <bdt data-type="conditional-block">
                                <bdt data-type="body">
                                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                                            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                            <bdt class="block-container if" data-type="if"
                                                id="24327c5d-a34f-f7e7-88f1-65a2f788484f" style="text-align: left;">
                                                <bdt data-type="conditional-block">
                                                    <bdt class="block-component"
                                                        data-record-question-key="user_post_content_option"
                                                        data-type="statement"></bdt><span
                                                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">The
                                                        Site does not offer users to submit or post content. We may
                                                        provide you with the opportunity to create, submit, post,
                                                        display, transmit, perform, publish, distribute, or broadcast
                                                        content and materials to us or on the Site, including but not
                                                        limited to text, writings, video, audio, photographs, graphics,
                                                        comments, suggestions, or personal information or other material
                                                        (collectively, "Contributions"). Contributions may be viewable
                                                        by other users of the Site and through third-party websites. As
                                                        such, any Contributions you transmit may be treated in
                                                        accordance with the Site Privacy Policy. When you create or make
                                                        available any Contributions, you thereby represent and warrant
                                                        that:<span
                                                            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                                            <bdt class="statement-end-if-in-editor"></bdt>
                                                        </span></span>
                                                </bdt>
                                            </bdt>
                                        </span></div>
                                </bdt>
                            </bdt>
                        </bdt>
                    </div>
                    <div class="MsoNormal" style="line-height: 1;"><br></div>
                </div>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; margin-left: 20px;"><span
                    style="color: rgb(89, 89, 89);"><span style="font-size: 14px;"><span
                            data-custom-class="body_text">1. &nbsp;The creation, distribution, transmission, public
                            display, or performance, and the accessing, downloading, or copying of your Contributions do
                            not and will not infringe the proprietary rights, including but not limited to the
                            copyright, patent, trademark, trade secret, or moral rights of any third party.<br>2.
                            &nbsp;You are the creator and owner of or have the necessary licenses, rights, consents,
                            releases, and permissions to use and to authorize us, the Site, and other users of the Site
                            to use your Contributions in any manner contemplated by the Site and these Terms of
                            Use.<br>3. &nbsp;You have the written consent, release, and/or permission of each and every
                            identifiable individual person in your Contributions to use the name or likeness of each and
                            every such identifiable individual person to enable inclusion and use of your Contributions
                            in any manner contemplated by the Site and these Terms of Use.<br>4. &nbsp;Your
                            Contributions are not false, inaccurate, or misleading.<br>5. &nbsp;Your Contributions are
                            not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain
                            letters, spam, mass mailings, or other forms of solicitation.<br>6. &nbsp;Your Contributions
                            are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
                            otherwise objectionable (as determined by us).<br>7. &nbsp;Your Contributions do not
                            ridicule, mock, disparage, intimidate, or abuse anyone.<br>8. &nbsp;Your Contributions are
                            not used to harass or threaten (in the legal sense of those terms) any other person and to
                            promote violence against a specific person or class of people.<br>9. &nbsp;Your
                            Contributions do not violate any applicable law, regulation, or rule.<br>10. &nbsp;Your
                            Contributions do not violate the privacy or publicity rights of any third party.<br>11.
                            &nbsp;Your Contributions do not contain any material that solicits personal information from
                            anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent
                            manner.<br>12. &nbsp;Your Contributions do not violate any applicable law concerning child
                            pornography, or otherwise intended to protect the health or well-being of minors;<br>13.
                            &nbsp;Your Contributions do not include any offensive comments that are connected to race,
                            national origin, gender, sexual preference, or physical handicap.<br>14. &nbsp;Your
                            Contributions do not otherwise violate, or link to material that violates, any provision of
                            these Terms of Use, or any applicable law or regulation.</span></span></span></div>
            <div class="MsoNormal" style="line-height: 1; margin-left: 20px;"><span
                    style="color: rgb(89, 89, 89);"><br></span></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">Any use of
                                    the Site or the Marketplace Offerings in violation of the foregoing violates these
                                    Terms of Use and may result in, among other things, termination or suspension of
                                    your rights to use the Site and the Marketplace Offerings.</span></div>
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong><span
                                        style="line-height: 24.5333px; font-size: 19px;">CONTRIBUTION
                                        LICENSE</span></strong></div>
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
                <bdt class="block-container if" data-type="if" id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
                    style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_post_content_option"
                            data-type="statement"></bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="body_text" style="text-align: justify; line-height: 1.5;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">You and Site agree that
                        we may access, store, process, and use any information and personal data that you provide
                        following the terms of the Privacy Policy and your choices (including settings).</span></span>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="text-align: justify; line-height: 1.5;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">By submitting suggestions
                    or other feedback regarding the Site, you agree that we can use and share such feedback for any
                    purpose without compensation to you.</span></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="text-align: justify; line-height: 1.5;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">We do not assert any
                        ownership over your Contributions. You retain full ownership of all of your Contributions and
                        any intellectual property rights or other proprietary rights associated with your Contributions.
                        We are not liable for any statements or representations in your Contributions provided by you in
                        any area on the Site. You are solely responsible for your Contributions to the Site and you
                        expressly agree to exonerate us from any and all responsibility and to refrain from any legal
                        action against us regarding your Contributions.</span></span></div>
        </div>
        <div align="center" style="text-align: left;">
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if" style="text-align: left;">
                                <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                            </bdt>
                        </span></span></span></div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="review_option" data-type="statement">
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
                <bdt class="block-container if" data-type="if" id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                    style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="mobile_app_option" data-type="statement">
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="socialnetwork_link_option"
                            data-type="statement"></bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong>SOCIAL MEDIA</strong>
            </div>
            <div class="MsoNormal" style="line-height: 1;"><br>
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">
                            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">As part of
                                    the functionality of the Site, you may link your account with online accounts you
                                    have with third-party service providers (each such account, a “Third-Party Account”)
                                    by either: (1) providing your Third-Party Account login information through the
                                    Site; or (2) allowing us to access your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account, as is permitted under
                                    the applicable terms and conditions that govern your use of each <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account. You represent and
                                    warrant that you are entitled to disclose your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account login information to us
                                    and/or grant us access to your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account, without breach by you
                                    of any of the terms and conditions that govern your use of the applicable <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account, and without obligating
                                    us to pay any fees or making us subject to any usage limitations imposed by the
                                    third-party service provider of the <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account. By granting us access
                                    to any <span style="font-size: 14.6667px;">Third-Party</span> Accounts, you
                                    understand that (1) we may access, make available, and store (if applicable) any
                                    content that you have provided to and stored in your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account (the “Social Network
                                    Content”) so that it is available on and through the Site via your account,
                                    including without limitation any friend lists and (2) we may submit to and receive
                                    from your <span style="font-size: 14.6667px;">Third-Party</span> Account additional
                                    information to the extent you are notified when you link your account with the <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account. Depending on the <span
                                        style="font-size: 14.6667px;">Third-Party</span> Accounts you choose and subject
                                    to the privacy settings that you have set in such <span
                                        style="font-size: 14.6667px;">Third-Party</span> Accounts, personally
                                    identifiable information that you post to your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Accounts may be available on
                                    and through your account on the Site. Please note that if a <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account or associated service
                                    becomes unavailable or our access to such <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account is terminated by the
                                    third-party service provider, then Social Network Content may no longer be available
                                    on and through the Site. You will have the ability to disable the connection between
                                    your account on the Site and your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Accounts at any time. PLEASE
                                    NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH
                                    YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                                    THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any Social Network
                                    Content for any purpose, including but not limited to, for accuracy, legality, or
                                    non-infringement, and we are not responsible for any Social Network Content. You
                                    acknowledge and agree that we may access your email address book associated with a
                                    <span style="font-size: 14.6667px;">Third-Party</span> Account and your contacts
                                    list stored on your mobile device or tablet computer solely for purposes of
                                    identifying and informing you of those contacts who have also registered to use the
                                    Site. You can deactivate the connection between the Site and your <span
                                        style="font-size: 14.6667px;">Third-Party</span> Account by contacting us using
                                    the contact information below or through your account settings (if applicable). We
                                    will attempt to delete any information stored on our servers that was obtained
                                    through such <span style="font-size: 14.6667px;">Third-Party</span> Account, except
                                    the username and profile picture that become associated with your account.</span>
                            </div>
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">
                            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                        </bdt>
                    </bdt>
                </bdt>
                <bdt class="block-container if" data-type="if" style="text-align: left;"><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="block-container if" data-type="if" style="text-align: left;">
                                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                                </bdt>
                            </span></span></span></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1;"><strong><span
                        style="line-height: 24.5333px; font-size: 19px;">SUBMISSIONS</span></strong></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">You acknowledge and agree
                that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site or
                the Marketplace Offerings ("Submissions") provided by you to us are non-confidential and shall become
                our sole property. We shall own exclusive rights, including all intellectual property rights, and shall
                be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose,
                commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral
                rights to any such Submissions, and you hereby warrant that any such Submissions are original with you
                or that you have the right to submit such Submissions. You agree there shall be no recourse against us
                for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="3rd_party_option" data-type="statement">
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="advertiser_option" data-type="statement">
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong><span
                        style="line-height: 24.5333px; font-size: 19px;">SITE MANAGEMENT</span></strong></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">We reserve the right, but not
                the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate
                legal action against anyone who, in our sole discretion, violates the law or these Terms of Use,
                including without limitation, reporting such user to law enforcement authorities; (3) in our sole
                discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to
                the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole
                discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable
                all files and content that are excessive in size or are in any way burdensome to our systems; and (5)
                otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the
                proper functioning of the Site and the Marketplace Offerings.</div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                    style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="privacy_policy_option"
                            data-type="statement"></bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong>PRIVACY
                    POLICY</strong></div>
            <div class="MsoNormal" style="line-height: 1;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">We care about data privacy
                    and security. Please review our Privacy Policy:<strong>&nbsp;<bdt
                            class="block-container question question-in-editor"
                            data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1" data-type="question"><a
                                href="https://www.WIZDOUGH.com/privacy" target="_blank"
                                data-custom-class="link">https://www.WIZDOUGH.com/privacy</a></bdt></strong>. By using the
                    Site or the Marketplace Offerings, you agree to be bound by our Privacy Policy, which is
                    incorporated into these Terms of Use. Please be advised the Site and the Marketplace Offerings are
                    hosted in <bdt class="block-component"></bdt>the <bdt class="question">United States</bdt>
                    <bdt class="block-component"></bdt>. If you access the Site or the Marketplace Offerings from any
                    other region of the world with laws or other requirements governing personal data collection, use,
                    or disclosure that differ from applicable laws in <span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="block-component"></bdt>the <bdt class="question">United States</bdt>
                        <bdt class="block-component"></bdt>
                    </span>, then through your continued use of the Site, you are transferring your data to <span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="block-component"></bdt>the <bdt class="question">United States</bdt>
                        <bdt class="block-component"></bdt>
                    </span>, and you expressly consent to have your data transferred to and processed in <span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="block-component"></bdt>the <bdt class="question">United States</bdt>
                        <bdt class="block-component"></bdt>
                    </span>.<bdt class="block-component"></bdt>
                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="user_u13_option"
                                data-type="statement"><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                </span></bdt>
                        </bdt>
                    </bdt>
                </span></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                </bdt>
                <bdt class="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="privacy_policy_followup"
                            data-type="statement" style="font-size: 14.6667px;"></bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="font-size: 14.6667px; text-align: left; line-height: 1.5;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="copyright_agent_option"
                            data-type="statement">
                            <bdt class="block-component"></bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="block-container if" data-type="if" style="text-align: left;">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="no_agent_clause_option"
                            data-type="statement">
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                        </bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 24.5333px; font-size: 19px;">TERM AND TERMINATION</span></strong>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">These Terms of Use shall
                    remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF
                    THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                    DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP
                    ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
                    OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE
                    LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND THE MARKETPLACE
                    OFFERINGS OR DELETE <bdt class="block-container if" data-type="if"
                        id="a6e121c2-36b4-5066-bf9f-a0a33512e768">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="user_account_option"
                                data-type="statement"></bdt>
                            <bdt data-type="body">YOUR ACCOUNT AND&nbsp;</bdt>
                        </bdt>
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                    DISCRETION.</span></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">If we terminate or suspend
                    your account for any reason, you are prohibited from registering and creating a new account under
                    your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
                    behalf of the third party. In addition to terminating or suspending your account, we reserve the
                    right to take appropriate legal action, including without limitation pursuing civil, criminal, and
                    injunctive redress.</span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 24.5333px; font-size: 19px;">MODIFICATIONS AND
                        INTERRUPTIONS</span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">We reserve the right to
                    change, modify, or remove the contents of the Site at any time or for any reason at our sole
                    discretion without notice. However, we have no obligation to update any information on our Site. We
                    also reserve the right to modify or discontinue all or part of the Marketplace Offerings without
                    notice at any time. We will not be liable to you or any third party for any modification, price
                    change, suspension, or discontinuance of the Site or the Marketplace Offerings.</span></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">We cannot guarantee the
                    Site and the Marketplace Offerings will be available at all times. We may experience hardware,
                    software, or other problems or need to perform maintenance related to the Site, resulting in
                    interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
                    discontinue, or otherwise modify the Site or the Marketplace Offerings at any time or for any reason
                    without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
                    inconvenience caused by your inability to access or use the Site or the Marketplace Offerings during
                    any downtime or discontinuance of the Site or the Marketplace Offerings. Nothing in these Terms of
                    Use will be construed to obligate us to maintain and support the Site or the Marketplace Offerings
                    or to supply any corrections, updates, or releases in connection therewith.</span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 24.5333px; font-size: 19px;">GOVERNING LAW</span></strong></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="block-component"></bdt>
                </span></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">These Terms of Use and your
                    use of the Site and the Marketplace Offerings are governed by and construed in accordance with the
                    laws of <bdt class="block-container if" data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="governing_law" data-type="statement">
                            </bdt>
                            <bdt data-type="body">the State of <bdt class="block-container question question-in-editor"
                                    data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3" data-type="question">California</bdt>
                            </bdt>
                        </bdt>
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt> applicable to agreements made and to be entirely performed within<bdt
                        class="block-container if" data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                        style="font-size: 14.6667px;">
                        <bdt data-type="conditional-block">&nbsp;<span
                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="block-container if" data-type="if"
                                    id="b86653c1-52f0-c88c-a218-e300b912dd6b">
                                    <bdt data-type="conditional-block">
                                        <bdt class="block-component" data-record-question-key="governing_law"
                                            data-type="statement"></bdt>
                                        <bdt data-type="body">the State of <bdt
                                                class="block-container question question-in-editor"
                                                data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3" data-type="question">
                                                California</bdt>
                                        </bdt>
                                    </bdt>
                                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                                </bdt><span style="font-size: 14.6667px;">,&nbsp;</span>without regard to its conflict
                                of law principles.<bdt class="block-component"></bdt>
                            </span></bdt>
                    </bdt></span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 24.5333px; font-size: 19px;">DISPUTE RESOLUTION</span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="block-component"></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="block-component"></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
                <strong>Informal Negotiations</strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 15px;">To expedite resolution and control the cost of any dispute, controversy, or
                    claim related to these Terms of Use (each a "Dispute" and collectively, the “Disputes”) brought by
                    either you or us (individually, a “Party” and collectively, the “Parties”), the Parties agree to
                    first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally
                    for at least <bdt class="question">thirty (30)</bdt> days before initiating arbitration. Such
                    informal negotiations commence upon written notice from one Party to the other Party.</span></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="statement-end-if-in-editor"></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
                <strong>Binding Arbitration</strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <bdt class="block-component"><span style="font-size: 15px;"></span></bdt><span
                    style="font-size: 15px;">If the Parties are unable to resolve a Dispute through informal
                    negotiations, the Dispute (except those Disputes expressly excluded below) will be finally and
                    exclusively resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
                    HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and
                    conducted under the Commercial Arbitration Rules of the American Arbitration Association ("AAA")
                    and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes ("AAA
                    Consumer Rules"), both of which are available at the AAA website <span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);"><a
                            data-custom-class="link" href="http://www.adr.org" rel="noopener noreferrer"
                            target="_blank">www.adr.org</a></span>. Your arbitration fees and your share of arbitrator
                    compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA
                    Consumer Rules. <bdt class="block-component"></bdt>If such costs are determined to by the arbitrator
                    to be excessive, we will pay all arbitration fees and expenses. <bdt
                        class="statement-end-if-in-editor"></bdt>The arbitration may be conducted in person, through the
                    submission of documents, by phone, or online. The arbitrator will make a decision in writing, but
                    need not provide a statement of reasons unless requested by either Party. The arbitrator must follow
                    applicable law, and any award may be challenged if the arbitrator fails to do so. Except where
                    otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in
                    <bdt class="block-component"></bdt>
                    <bdt class="question">Santa Clara</bdt>,<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="question">California</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>. Except as otherwise provided herein, the Parties may
                    litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm,
                    modify, vacate, or enter judgment on the award entered by the arbitrator.
                </span>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 15px;">If for any reason, a Dispute proceeds in court rather than arbitration, the
                    Dispute shall be commenced or prosecuted in the</span>
                <bdt class="block-component" style="font-size: 15px;"></bdt><span style="font-size: 15px;">&nbsp;state
                    and federal courts</span>
                <bdt class="statement-end-if-in-editor" style="font-size: 15px;"></bdt><span
                    style="font-size: 15px;">&nbsp;located in</span>
                <bdt class="block-component" style="font-size: 15px;"></bdt>
                <bdt class="question">Santa Clara</bdt><span style="font-size: 15px;">,</span>
                <bdt class="statement-end-if-in-editor" style="font-size: 15px;"></bdt>
                <bdt class="block-component" style="font-size: 15px;"></bdt>
                <bdt class="question" style="font-size: 15px;">California</bdt>
                <bdt class="statement-end-if-in-editor" style="font-size: 15px;"></bdt><span style="font-size: 15px;">,
                    and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, and
                    forum non conveniens with respect to venue and jurisdiction in such<bdt class="block-component">
                    </bdt> state and federal courts<bdt class="statement-end-if-in-editor"></bdt>. Application of the
                    United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer
                    Information Transaction Act (UCITA) are excluded from these Terms of Use.</span>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 15px;">
                    <bdt class="block-component"></bdt>In no event shall any Dispute brought by either Party related in
                    any way to the Site be commenced more than <bdt class="question">one (1)</bdt> years after the cause
                    of action arose. <bdt class="statement-end-if-in-editor"></bdt>If this provision is found to be
                    illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that
                    portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by
                    a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties
                    agree to submit to the personal jurisdiction of that court.<bdt class="block-component"></bdt>
                </span></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
                <strong>Restrictions</strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">The Parties
                agree that any arbitration shall be limited to the Dispute between the Parties individually. The the
                full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is
                no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class
                action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported
                representative capacity on behalf of the general public or any other persons.</div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
                <bdt class="block-component"></bdt><strong>Exceptions to Informal Negotiations and Arbitration</strong>
                <bdt class="statement-end-if-in-editor"></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <bdt class="block-component"></bdt>The Parties agree that the following Disputes are not subject to the
                above provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to
                enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party;
                (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or
                unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or
                unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of
                this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit
                to the personal jurisdiction of that court.<bdt class="statement-end-if-in-editor"></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="statement-end-if-in-editor">
                    <bdt class="statement-end-if-in-editor"></bdt>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="font-size: 19px;">CORRECTIONS</span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">There may
                be information on the Site that contains typographical errors, inaccuracies, or omissions that may
                relate to the Marketplace Offerings, including descriptions, pricing, availability, and various other
                information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or
                update the information on the Site at any time, without prior notice.</div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 19px; color: rgb(0, 0, 0);"><strong>DISCLAIMER</strong></span></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">THE SITE IS PROVIDED
                    ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
                    AGREE THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
                    FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                    IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT
                    LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
                    THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
                    WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY
                    FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
                    PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
                    YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF
                    OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                    INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION
                    TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
                    MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY
                    ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
                    ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                    OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE,
                    OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
                    THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
                    APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
                    PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU
                    AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                    PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
                    SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">LIMITATIONS OF
                        LIABILITY</span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span data-custom-class="body_text">IN NO EVENT WILL WE
                        OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF
                        WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span>
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943"><span
                            data-custom-class="body_text">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="limitations_liability_option"
                                    data-type="statement"></bdt>
                                <bdt data-type="body">NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                                    LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION,
                                    WILL AT ALL TIMES BE LIMITED TO <bdt class="block-container if" data-type="if"
                                        id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                        <bdt data-type="conditional-block">
                                            <bdt class="block-component"
                                                data-record-question-key="limitations_liability_option"
                                                data-type="statement"></bdt>
                                            <bdt data-type="body">THE
                                                AMOUNT PAID, IF ANY, BY YOU TO US<bdt class="block-container if"
                                                    data-type="if" id="19e172cb-4ccf-1904-7c06-4251800ba748">
                                                    <bdt data-type="conditional-block">
                                                        <bdt class="block-component"
                                                            data-record-question-key="limilation_liability_time_option"
                                                            data-type="statement">&nbsp;</bdt>
                                                        <bdt data-type="body"><span
                                                                style="font-size: 11pt; color: rgb(89, 89, 89); text-transform: uppercase;">DURING
                                                                THE <bdt
                                                                    class="block-container question question-in-editor"
                                                                    data-id="5dd68d46-ed6f-61c7-cd66-6b3f424b6bdd"
                                                                    data-type="question">six (6)</bdt> mONTH PERIOD
                                                                PRIOR TO ANY CAUSE OF ACTION ARISING</span></bdt>
                                                    </bdt>
                                                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                        <bdt data-type="conditional-block">
                                            <bdt class="block-component"
                                                data-record-question-key="limitations_liability_option"
                                                data-type="statement">.&nbsp;</bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </bdt>
                        </span></bdt>
                </span><span data-custom-class="body_text">CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                    LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
                    APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
                    HAVE ADDITIONAL RIGHTS.</span>
                <bdt class="statement-end-if-in-editor" data-type="close"><span data-custom-class="body_text"></span>
                </bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">INDEMNIFICATION</span></strong>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You agree to
                    defend, indemnify, and hold us harmless, including our subsidiaries,
                    affiliates, and all of our respective officers, agents, partners, and
                    employees, from and against any loss, damage, liability, claim, or demand, including
                    reasonable attorneys’ fees and expenses, made by any third party due to or
                    arising out of: <bdt class="block-container if" data-type="if"
                        id="475fffa5-05ca-def8-ac88-f426b238903c">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="user_post_content_option"
                                data-type="statement"></bdt>
                        </bdt>(<span style="font-size: 14.6667px;">1</span>) use of the Site; (<span
                            style="font-size: 14.6667px;">2</span>) breach of these Terms of Use; (<span
                            style="font-size: 14.6667px;">3</span>) any breach of your representations and warranties
                        set forth in these Terms of Use; (<span style="font-size: 14.6667px;">4</span>) your violation
                        of the rights of a third party, including but not limited to intellectual property rights; or
                        (<span style="font-size: 14.6667px;">5</span>) any overt harmful act toward any other user of
                        the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the
                        right, at your expense, to assume the exclusive defense and control of any matter for which you
                        are required to indemnify us, and you agree to cooperate, at your expense, with our defense of
                        such claims. We will use reasonable efforts to notify you of any such claim, action, or
                        proceeding which is subject to this indemnification upon becoming aware of it.
                    </bdt></span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">USER DATA</span></strong>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We will maintain
                    certain data that you transmit to the Site for the purpose of managing the
                    performance of the Site, as well as data relating to your use of the Site. Although we perform
                    regular routine backups
                    of data, you are solely responsible for all data that you transmit or that
                    relates to any activity you have undertaken using the Site. You agree
                    that we shall have no liability to you for any loss or corruption of any such
                    data, and you hereby waive any right of action against us arising from any such
                    loss or corruption of such data.</span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">ELECTRONIC COMMUNICATIONS,
                        TRANSACTIONS, AND SIGNATURES</span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Visiting the Site, sending us emails, and completing
                    online forms constitute electronic communications. You consent to receive electronic communications,
                    and you agree that all agreements, notices, disclosures, and other communications we provide to you
                    electronically, via email and on the Site, satisfy any legal requirement that such communication be
                    in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
                    RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                    COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes,
                    regulations, rules, ordinances, or other laws in any jurisdiction which require an original
                    signature or delivery or retention of non-electronic records, or to payments or the granting of
                    credits by any means other than electronic means.</span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="block-component"></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">CALIFORNIA USERS
                        AND RESIDENTS</span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">If any complaint
                    with us is not satisfactorily resolved, you can contact the Complaint
                    Assistance Unit of the Division of Consumer Services of the California
                    Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N
                    112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916)
                    445-1254.</span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                <bdt class="statement-end-if-in-editor"></bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">MISCELLANEOUS</span></strong>
            </div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">These Terms of Use and any policies or operating rules
                    posted by us on the Site or in respect to the Site constitute the entire agreement and understanding
                    between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use
                    shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest
                    extent permissible by law. We may assign any or all of our rights and obligations to others at any
                    time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by
                    any cause beyond our reasonable control. If any provision or part of a provision of these Terms of
                    Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is
                    deemed severable from these Terms of Use and does not affect the validity and enforceability of any
                    remaining provisions. There is no joint venture, partnership, employment or agency relationship
                    created between you and us as a result of these Terms of Use or use of the Site. You agree that
                    these Terms of Use will not be construed against us by virtue of having drafted them. You hereby
                    waive any and all defenses you may have based on the electronic form of these Terms of Use and the
                    lack of signing by the parties hereto to execute these Terms of Use.<bdt class="block-component">
                    </bdt></span></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5; text-align: left;">
                <strong><span style="line-height: 115%; font-family: Arial;"><span style="font-size: 19px;">CONTACT
                            US</span></span></strong></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">In order to resolve a complaint regarding the Site or to
                    receive further information regarding use of the Site, please contact us at:</span></div>
            <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <strong><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><strong>
                            <bdt class="question">info@wizdough.com</bdt>
                        </strong></span></strong></div>
        </div>
    </div>
    <style>
        ul {
            list-style-type: square;
        }

        ul>li>ul {
            list-style-type: circle;
        }

        ul>li>ul>li>ul {
            list-style-type: square;
        }

        ol li {
            font-family: Arial;
        }
    </style>

</div>
`;